import { browser } from "$app/environment";
import { ApolloClient, InMemoryCache, split } from "@apollo/client/core";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import userStore from "src/stores/user";
import { WebSocket } from "ws";

const httpLink = new BatchHttpLink({
	uri: import.meta.env.VITE_HASURA_HTTP_URI,
});

const wsLink = browser
	? new GraphQLWsLink(
			createClient({
				url: import.meta.env.VITE_HASURA_WS_URI,
				connectionParams: async () => {
					const token = await userStore.getAccessToken();

					if (token) {
						return {
							headers: {
								Authorization: `Bearer ${token}`,
							},
						};
					} else {
						return {};
					}
				},
				webSocketImpl: WebSocket,
			}),
		)
	: undefined;

const authLink = setContext(async (_, { headers, connectionParams }) => {
	if (!browser) {
		return {
			headers: {
				"x-hasura-admin-secret": import.meta.env.VITE_HASURA_SECRET,
				...connectionParams?.headers,
				...headers,
			},
		};
	}

	const token = await userStore.getAccessToken();

	if (token) {
		return {
			headers: {
				Authorization: `Bearer ${token}`,
				...connectionParams?.headers,
				...headers,
			},
			connectionParams: {
				...connectionParams,
				headers: { Authorization: `Bearer ${token}` },
			},
		};
	} else {
		return {
			headers: { ...headers },
			connectionParams: { ...connectionParams },
		};
	}
});

const link = browser
	? authLink.concat(
			split(
				({ query }) => {
					const definition = getMainDefinition(query);
					return (
						definition.kind === "OperationDefinition" &&
						definition.operation === "subscription"
					);
				},
				wsLink,
				httpLink,
			),
		)
	: authLink.concat(httpLink);

const client = new ApolloClient({
	link,
	cache: new InMemoryCache(),
});

export { client };
export default client;
