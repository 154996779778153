import { browser } from "$app/environment";
import { goto } from "$app/navigation";
import type {
	Auth0Client,
	Auth0ClientOptions,
	PopupLoginOptions,
	User,
} from "@auth0/auth0-spa-js";
import createAuth0Client from "@auth0/auth0-spa-js";
import Swal from "sweetalert2";

class Auth0Service {
	private _client: Auth0Client;
	private static readonly CONFIG: Auth0ClientOptions = {
		domain: import.meta.env.VITE_AUTH0_DOMAIN,
		client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
		audience: import.meta.env.VITE_AUTH0_AUDIENCE,
		cacheLocation: "localstorage",
		useRefreshTokens: true,
	};

	private async createClient(): Promise<void> {
		// auth0 can only auth in browser
		if (browser) this._client = await createAuth0Client(Auth0Service.CONFIG);
	}

	public static async build(): Promise<Auth0Service> {
		const c = new Auth0Service();
		await c.createClient();
		return c;
	}

	public async loginWithPopup(options?: PopupLoginOptions): Promise<void> {
		try {
			await this._client.loginWithPopup(options);
		} catch (e) {
			console.error(e);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Algo de errado aconteceu",
				footer: '<a href="">Porque estou tendo este erro?</a>',
			});
		}
	}

	public async trySilentAuth() {}

	public async logout(): Promise<void> {
		await this._client.logout({
			returnTo: window.location.origin,
		});
	}

	/**
	 * Auth0 stores these informations in cache
	 */
	get accessToken(): Promise<string> {
		return this._client?.getTokenSilently();
	}

	get user(): Promise<User> {
		return this._client?.getUser();
	}

	get isAuthenticated(): Promise<boolean> {
		return this._client?.isAuthenticated();
	}
}

const auth0Service = Auth0Service.build();
export default auth0Service;
