import { browser } from "$app/environment";
import { goto } from "$app/navigation";
import type { User } from "@auth0/auth0-spa-js";
import {
	DeleteTagsOfTeamDoc,
	DeleteTeamMemberDoc,
	GetGroupsOfTeamDoc,
	GetTagsDoc,
	GetTeamCoreByIdDoc,
	GetTeamCoreDoc,
	GetTeamsOfUserDoc,
	GetUserCoreDoc,
	GetUserCoreQuery,
	InsertActionUpdateProfile,
	InsertTagsToTeamDoc,
	InsertTeamNameDoc,
	RespondTeamInvitationDoc,
	UpdateTeamDoc,
	UpdateTeamSocialMediaDoc,
	UpdateUserNameDoc,
	UpdateUserOccupationDoc,
	UploadProfilePictureDoc,
	type DeleteTagsOfTeamMutation,
	type DeleteTeamMemberMutation,
	type GetGroupsOfTeamQuery,
	type GetTagsQuery,
	type GetTeamCoreByIdQuery,
	type GetTeamCoreQuery,
	type GetTeamsOfUserQuery,
	type InsertTagsToTeamMutation,
	type InsertTeamNameMutation,
	type RespondTeamInvitationMutation,
	type UpdateTeamMutation,
	type UpdateTeamSocialMediaMutation,
	type UpdateUserNameMutation,
	type UpdateUserOccupationMutation,
	type UploadProfilePictureMutation,
} from "src/graphql-operations";
import { client } from "src/lib/apollo/client-provider";
import auth0Service from "src/lib/auth0-service";
import { UpdateTeamProps } from "src/types/stores/teams";
import type { UpdateUserProps } from "src/types/stores/users";
import { mutation, query, setClient } from "svelte-apollo";
import Swal from "sweetalert2";

/**
 * Acts as a facade to Auth0Service and session.
 * Contains both auth0User and hasuraUser.
 */

class UserStore {
	private async getHasuraUser() {
		const auth0 = await auth0Service;
		if (await auth0.isAuthenticated) {
			const user = await query<GetUserCoreQuery>(GetUserCoreDoc, {
				variables: { id: (await auth0.user).sub },
			}).result();
			if (user.error || user.errors) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Algo de errado aconteceu",
					footer: '<a href="">Porque estou tendo este erro?</a>',
				});
				await this.logout();
				return null;
			}

			return user.data?.users[0];
		}
		return null;
	}

	public async loginWithPopup(redirectUrl = "") {
		const auth0 = await auth0Service;
		await auth0.loginWithPopup();
		if (await auth0.isAuthenticated) {
			setClient(client);
			await this.getHasuraUser(); // make request and store in cache
			if (redirectUrl) return await goto(redirectUrl);
			return location.reload();
		}
	}

	public async logout() {
		await (await auth0Service).logout();
		localStorage.removeItem("team_id");
	}

	public async getProject(
		teamId?: string,
	): Promise<GetTeamCoreByIdQuery["teams_by_pk"]> {
		if (!teamId) teamId = this.getSelectedProjectId();
		const team = await query<GetTeamCoreByIdQuery>(GetTeamCoreByIdDoc, {
			variables: { id: teamId },
		}).result();
		if (team.error || team.errors) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Algo de errado aconteceu",
				footer: '<a href="">Porque estou tendo este erro?</a>',
			});
			return null;
		}
		return team.data?.teams_by_pk;
	}

	public async getAllUserProjects(): Promise<GetTeamsOfUserQuery["teams"]> {
		const auth0 = await auth0Service;
		if (await auth0.isAuthenticated) {
			const team = await query<GetTeamsOfUserQuery>(GetTeamsOfUserDoc, {
				variables: { user_id: (await auth0.user).sub },
			}).result();
			if (team.error || team.errors) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Algo de errado aconteceu",
					footer: '<a href="">Porque estou tendo este erro?</a>',
				});
				return;
			}
			return team.data?.teams;
		}
		return;
	}

	public async getUserPendingProjectsInvites(): Promise<
		GetTeamCoreQuery["team_invites"]
	> {
		const auth0 = await auth0Service;
		if (await auth0.isAuthenticated) {
			const invites = await query<GetTeamCoreQuery>(GetTeamCoreDoc, {
				variables: { user_id: (await auth0.user).sub },
			}).result();
			if (invites.error || invites.errors) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Algo de errado aconteceu",
					footer: '<a href="">Porque estou tendo este erro?</a>',
				});
				return null;
			}
			return invites.data?.team_invites;
		}
		return null;
	}

	public async getUserTeamGroups(
		teamId?: string,
	): Promise<GetGroupsOfTeamQuery["groups"]> {
		if (!teamId) teamId = this.getSelectedProjectId();
		const groups = await query<GetGroupsOfTeamQuery>(GetGroupsOfTeamDoc, {
			variables: { team_id: teamId },
		}).result();
		if (groups.error || groups.errors) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Algo de errado aconteceu",
				footer: '<a href="">Porque estou tendo este erro?</a>',
			});
			return null;
		}
		return groups.data?.groups;
	}

	public async respondInvitation(
		invite_id: string,
		accepted: boolean,
	): Promise<void> {
		const respondTeamInvite = mutation<RespondTeamInvitationMutation>(
			RespondTeamInvitationDoc,
		);
		await respondTeamInvite({
			variables: { invite_id: invite_id, accept: accepted },
		});
	}

	public async updateUser(user: UpdateUserProps): Promise<void> {
		const oldUser = await this.getUser();

		const uploadProfilePicture = mutation<UploadProfilePictureMutation>(
			UploadProfilePictureDoc,
		);
		const insertUserName = mutation<UpdateUserNameMutation>(UpdateUserNameDoc);
		const updateUserOccupation = mutation<UpdateUserOccupationMutation>(
			UpdateUserOccupationDoc,
		);

		if (user.image)
			await uploadProfilePicture({
				variables: { user_id: oldUser.id, b64_img: user.image },
			});

		if (user.name != oldUser.names[0]?.name)
			await insertUserName({
				variables: { user_id: oldUser.id, name: user.name },
			});

		if (user.occupation != oldUser.occupation)
			await updateUserOccupation({
				variables: { user_id: oldUser.id, occupation: user.occupation },
			});

		InsertActionUpdateProfile({
			variables: {
				user_id: oldUser.id,
				team_id: this.getSelectedProjectId(),
			},
		});
	}

	public async updateTeam(team: UpdateTeamProps): Promise<void> {
		const oldTeam = await this.getProject();

		const updateTeam = mutation<UpdateTeamMutation>(UpdateTeamDoc);
		const insertTeamName = mutation<InsertTeamNameMutation>(InsertTeamNameDoc);
		const deleteTeamMember =
			mutation<DeleteTeamMemberMutation>(DeleteTeamMemberDoc);
		const updateSocialMedia = mutation<UpdateTeamSocialMediaMutation>(
			UpdateTeamSocialMediaDoc,
		);
		const deleteTagsOfTeam =
			mutation<DeleteTagsOfTeamMutation>(DeleteTagsOfTeamDoc);
		const insertTagsToTeam =
			mutation<InsertTagsToTeamMutation>(InsertTagsToTeamDoc);

		if (team.cnpj != oldTeam.cnpj || team.description != oldTeam.description)
			await updateTeam({
				variables: {
					team_id: oldTeam.id,
					cnpj: team.cnpj,
					description: team.description,
				},
			});

		if (team.name != oldTeam.names[0]?.name)
			await insertTeamName({
				variables: { team_id: oldTeam.id, name: team.name },
			});

		for (const member_id of team.removedMembers)
			await deleteTeamMember({
				variables: { team_id: oldTeam.id, user_id: member_id },
			});

		const oldSocialMediasVals = Object.values(oldTeam?.social_media || {})
			.sort()
			.filter((i) => i !== "social_medias");
		const socialMediasVals = Object.values(team?.socialMedias).sort();
		if (JSON.stringify(oldSocialMediasVals) != JSON.stringify(socialMediasVals))
			await updateSocialMedia({
				variables: { team_id: oldTeam.id, ...team?.socialMedias },
			});

		const oldTags = oldTeam?.tags.map((t) => t.tag.value).sort();
		team.tags = team.tags.sort();
		if (JSON.stringify(oldTags) != JSON.stringify(team.tags)) {
			await deleteTagsOfTeam({
				variables: { team_id: oldTeam.id },
			});

			const allTags = {};
			[...(await query<GetTagsQuery>(GetTagsDoc).result()).data?.tags].forEach(
				(t) => (allTags[t.value] = t.id),
			);

			const teams_tags_insert_input = [
				...team.tags.map((val) => {
					if (allTags[val])
						return { team_id: oldTeam.id, tag_id: allTags[val] };
					return { tag: { data: { value: val } }, team_id: oldTeam.id };
				}),
			];

			await insertTagsToTeam({
				variables: { objects: teams_tags_insert_input },
			});

			InsertActionUpdateProfile({
				variables: {
					user_id: (await this.getUser()).id,
					team_id: oldTeam.id,
				},
			});
		}
	}

	/**
	 * All this information stays in cache.
	 */
	public async isAuthenticated(): Promise<boolean> {
		return await (
			await auth0Service
		).isAuthenticated;
	}

	public async getAccessToken(): Promise<string> {
		if (await this.isAuthenticated())
			return await (
				await auth0Service
			).accessToken;
		return "";
	}

	public async getAuth0User(): Promise<User> {
		return await (
			await auth0Service
		).user;
	}

	public async getUser() {
		return await this.getHasuraUser();
	}

	public setSelectedProject(team_id: string): void {
		localStorage.setItem("team_id", team_id);
	}

	public getSelectedProjectId(): string {
		if (browser) return localStorage.getItem("team_id");
	}
}

export class UserRoles {
	public static ADMIN = "admin";
	public static EDITOR = "editor";
	public static MANAGER = "manager";
	public static MENTOR = "mentor";
	public static USER = "user";
}

const userStore = new UserStore();
export default userStore;
